import React, { useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { types as sdkTypes } from '../../util/sdkLoader';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_ITEM,
  LINE_ITEM_HOUR,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { ensureUser, userDisplayNameAsString } from '../../util/data';
import { defaultVatPercentage } from '../../util/misc';
import {
  BOOKING_PROCESS_NAME,
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';
import SmallPrint from './SmallPrint/SmallPrint';
import { ModalInMobile, PrimaryButton, AvatarSmall, H1, H2, NamedLink } from '../../components';

import css from './OrderPanel.module.css';

const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();
const { Money } = sdkTypes;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  // if (isOwnListing || isClosed) {
  if (isClosed) {
    const isWindowDefined = typeof window !== "undefined";
    isWindowDefined && window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location
) => {
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.
  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => openOrderModal(isOwnListing, isClosed, history, location);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const { price, publicData, validListingTypes, intl, crossPrice } = props;
  const { listingType, vat } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  };

  const vatPercentage = vat && vat !== 'no-vat' ? defaultVatPercentage : null;

  return <div className={`${css.priceContainer} ${crossPrice && css.crossedPrice}`}>
    {crossPrice && <div className={css.crossLine} />}
    <p className={css.price}>{formatMoney(intl, price)}</p>
    <div className={css.vatColor}>+ VAT {vatPercentage ? vatPercentage : 0}%</div>
  </div>
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    params,
    displayInfoBox,
    transaction,
    addToCartDisabled,
    shouldCartRedirectToSignup,
    isAddedToCart,
    onAddToUserCart,
    addToCartInProgress,
    addToCartError,
  } = props;

  const publicData = listing?.attributes?.publicData || {};
  const { unitType, transactionProcessAlias = '', noPrice, vat } = publicData || {};
  const transactionPrice = transaction?.attributes?.metadata?.price;
  const transactionMaxLength = transaction?.attributes?.metadata?.numberOfDays;

  const vatPercentage = vat && vat !== 'no-vat' ? defaultVatPercentage : null;

  const convertedTransctionPrice = transactionPrice ? new Money(transactionPrice.amount, transactionPrice.currency) : null;
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;
  const price = listing?.attributes?.price;
  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;

  const showPriceMissing = isPaymentProcess && !price && !noPrice;

  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };
  const showInvalidCurrency = isPaymentProcess && !noPrice && price?.currency !== marketplaceCurrency;
  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);
  const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone;

  const shouldHaveBookingDates =
    isBooking && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const hasTransaction = !!transaction
  const hidePurchase = noPrice && hasTransaction ? !transactionPrice : noPrice
  const noPriceWithoutTransaction = noPrice && !hasTransaction
  const noPriceWithTransaction = noPrice && hasTransaction

  const isPurchase = isPurchaseProcess(processName) && !hidePurchase;
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = isPurchase && typeof currentStock === 'number';

  const showInquiryForm = processName === INQUIRY_PROCESS_NAME || noPriceWithoutTransaction;

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);
  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  // Handle triggering submit for final form since we can't pass that handlesubmit to the contact
  const [contactForPricingFlag, setContactForPricingFlag] = useState(false);
  const handleContactForPricing = () => {
    setContactForPricingFlag(true);
  };

  const user = ensureUser(author);
  const userPublicData = user?.attributes?.profile?.publicData || {};
  const { isUsingSpecialRentalPricing, specialRentalPricingLabel } = userPublicData

  const isSaleFlow = publicData?.listingType.includes('for-sale');

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.modalHeading}>
          <H1 className={css.heading}>{title}</H1>
        </div>

        <div className={css.orderHeading}>
          {titleDesktop ? titleDesktop : <H2 className={titleClasses}>{title}</H2>}
          {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
        </div>

        {price && !noPrice ? (
          <div>
            <PriceMaybe
              price={price}
              publicData={publicData}
              validListingTypes={validListingTypes}
              intl={intl}
              crossPrice={convertedTransctionPrice}
            />
          </div>
        ) : (
          <div className={css.contactForPricing} onClick={handleContactForPricing}>
            <FormattedMessage id="ListingCard.contacForPricing" />
          </div>
        )}

        {convertedTransctionPrice ? null : <div className={css.textUpperCaseForSaleForRent}>
          {transactionMaxLength ? <FormattedMessage
            id="OrderPanel.offerLength"
            values={{ transactionMaxLength }}
          /> : <>
            <FormattedMessage id={
              isSaleFlow
                ? "OrderPanel.perUnit"
                :  specialRentalPricingLabel
                  ? specialRentalPricingLabel
                  : "OrderPanel.basePriceForRent"
            } values={{ unitType }} />{' '}
            <FormattedMessage
              id={
                isSaleFlow
                  ? 'ListingCard.forSale'
                  : 'ListingCard.forRent'
              }
            />
          </>}
        </div>}

        {isSaleFlow || convertedTransctionPrice
          ? null
          : <div className={css.rentBasePriceInfo}>
            <FormattedMessage id="OrderPanel.rentBasePriceInfo" />
            {/* <SmallPrint authorId={author.id.uuid} onlyLink={true} /> */}
          </div>
        }

        {convertedTransctionPrice ? <div className={css.proposePriceWrapper}>
          <div className={css.proposePriceTitle}>
            <FormattedMessage id="OrderPanel.proposePriceTitle" />
          </div>

          <PriceMaybe
            price={convertedTransctionPrice}
            publicData={publicData}
            validListingTypes={validListingTypes}
            intl={intl}
          />

          <div className={css.proposePriceLength}>
            <FormattedMessage
              id="OrderPanel.proposePriceLength"
              values={{ transactionMaxLength }}
            />
          </div>
        </div> : null}

        <div className={css.author}>
          <AvatarSmall user={author} className={css.providerAvatar} />
          <span className={css.providerNameLinked}>
            <FormattedMessage id="OrderPanel.author" values={{ customDisplayName: authorLink }} />
          </span>
          <span className={css.providerNamePlain}>
            <FormattedMessage id="OrderPanel.author" values={{ customDisplayName: authorDisplayName }} />
          </span>
        </div>

        {showPriceMissing ? (
          <PriceMissing />
        ) : showInvalidCurrency ? (
          <InvalidCurrency />
        ) : showBookingTimeForm ? (
          <BookingTimeForm
            className={css.bookingForm}
            formId="OrderPanelBookingTimeForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            params={params}
            displayInfoBox={displayInfoBox}
            displayAdditional={!isSaleFlow}
            mobileCloseModel={() => {
              if (isOrderOpen) closeOrderModal(history, location);
              onContactUser();
            }}
            isMobile={isOrderOpen}
            userPublicData={userPublicData}
          />
        ) : showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="OrderPanelBookingDatesForm"
            lineItemUnitType={lineItemUnitType}
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            dayCountAvailableForBooking={dayCountAvailableForBooking}
            transactionMaxLength={transactionMaxLength}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            monthlyTimeSlots={monthlyTimeSlots}
            onFetchTimeSlots={onFetchTimeSlots}
            timeZone={timeZone}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            params={params}
            displayInfoBox={displayInfoBox}
            displayAdditional={!isSaleFlow}
            mobileCloseModel={() => {
              if (isOrderOpen) closeOrderModal(history, location);
              onContactUser();
            }}
            isMobile={isOrderOpen}
            userPublicData={userPublicData}
            transaction={transaction}
            authorId={author.id.uuid}
            addToCartDisabled={addToCartDisabled}
            shouldCartRedirectToSignup={shouldCartRedirectToSignup}
            isAddedToCart={isAddedToCart}
            onAddToUserCart={onAddToUserCart}
            addToCartInProgress={addToCartInProgress}
            addToCartError={addToCartError}
          />
        ) : showProductOrderForm ? (
          <ProductOrderForm
            formId="OrderPanelProductOrderForm"
            onSubmit={onSubmit}
            price={price}
            marketplaceCurrency={marketplaceCurrency}
            currentStock={currentStock}
            pickupEnabled={pickupEnabled}
            shippingEnabled={shippingEnabled}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            marketplaceName={marketplaceName}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            onContactUser={onContactUser}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            params={params}
            displayInfoBox={displayInfoBox}
            displayAdditional={!isSaleFlow}
            mobileCloseModel={() => {
              if (isOrderOpen) closeOrderModal(history, location);
              onContactUser();
            }}
            isMobile={isOrderOpen}
            transaction={transaction}
            userPublicData={userPublicData}
            authorId={author.id.uuid}
            addToCartDisabled={addToCartDisabled}
            shouldCartRedirectToSignup={shouldCartRedirectToSignup}
            isAddedToCart={isAddedToCart}
            onAddToUserCart={onAddToUserCart}
            addToCartInProgress={addToCartInProgress}
            addToCartError={addToCartError}
          />
        ) : showInquiryForm ? (
          <InquiryWithoutPaymentForm
            formId="OrderPanelInquiryForm"
            onSubmit={(e) => {
              if (noPrice) {
                if (isOrderOpen) closeOrderModal(history, location);
                onContactUser();
              } else {
                onSubmit(e)
              }
            }}
            params={params}
            displayInfoBox={displayInfoBox}
            contactForPricingFlag={contactForPricingFlag}
            displayAdditional={!isSaleFlow}
            userPublicData={userPublicData}
            authorId={author.id.uuid}
            isOwnListing={isOwnListing}
          />
        ) : noPriceWithTransaction ? (
          <FormattedMessage id="OrderPanel.requiresSetPrice" />
        ) : !isKnownProcess ? (
          <p className={css.errorSidebar}>
            <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
          </p>
        ) : null}
        {transaction ? null : <div className={css.mobileBottomSpacing} />}
      </ModalInMobile>
      <div className={css.openOrderForm}>
        <div className={css.priceContainerInCTA}>
          {price ? (
            <div>
              <div className={css.mobilePrice} title={priceTitle}>
                {formattedPrice}
              </div>
              <div className={css.vatColor}>+ VAT  {vatPercentage ? vatPercentage : 0}%</div>
              <div className={css.perUnitInCTA}>
                {isSaleFlow ? null : <>
                  <FormattedMessage id={
                    specialRentalPricingLabel
                      ? specialRentalPricingLabel
                      : "OrderPanel.basePriceForRent"
                  } values={{ unitType }} />
                  <br />
                </>}
                <FormattedMessage
                  id={
                    isSaleFlow
                      ? 'ListingCard.forSale'
                      : 'ListingCard.forRent'
                  }
                />
              </div>
            </div>
          ) : (
            <div className={css.contactForPricing} onClick={handleContactForPricing}>
              <FormattedMessage id="ListingCard.contacForPricing" />
            </div>
          )}
        </div>

        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (
          <div className={css.buttonMobile}>
            <PrimaryButton
              onClick={handleSubmit(
                isOwnListing,
                isClosed,
                showInquiryForm,
                onSubmit,
                history,
                location
              )}
              disabled={isOutOfStock}
            >
              {isBooking ? (
                // <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
                <FormattedMessage id="OrderPanel.ctaButtonMessageOpenBookingPanel" />
              ) : isOutOfStock ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
              ) : isPurchase ? (
                // <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
                <FormattedMessage id="OrderPanel.ctaButtonMessageOpenOrderPanel" />
              ) : (
                <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
              )}
            </PrimaryButton>
          </div>
        )}
      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
  displayInfoBox: false,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  displayInfoBox: bool,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);
